import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Eigentum = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Eigentum – Pro und Contra" showCalc={false} />
            <Article>
                <p>
                    Zu den fundamentalsten Entscheidungen rund um das Thema Wohnen gehört die zwischen Eigentum und
                    Miete. Während das Eigenheim für die einen ein Lebenstraum ist, genießen die anderen die geringere
                    Verantwortung des Mieter*innendaseins. Doch die persönlichen Präferenzen einmal beiseite gelassen:
                    Was spricht tatsächlich für den Kauf eines Hauses und was dagegen?
                </p>
                <hr />

                <h2>Pro Eigentum</h2>
                <h3>Immobilien als Wertanlage</h3>
                <p>
                    Immobilien sind nicht nur irgendeine Art der Wertanlage, sie sind sowohl die stabilste als auch die
                    bewährteste. Darüber hinaus ist diese Investitionsform auch transgenerational: Wohnungen und Häuser
                    können ganz einfach an Familienmitglieder vererbt oder verschenkt werden – sogar ohne Kaufvertrag.
                    Mit dem Kauf einer Wohnung oder eines Hauses ist somit der erste Schritt nicht nur zu deinem
                    Wohlstand getan, sondern ggf. auch zu dem deiner Kinder und Kindeskinder.
                </p>
                <h3>Sinkende Kosten</h3>
                <p>
                    Die Fixkosten, die du für deinen Wohnraum bezahlen musst, sinken mit der Zeit – schließlich ist
                    jeder{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredit
                    </Link>{" "}
                    einmal abbezahlt. Ab diesem Zeitpunkt musst du nichts als die Betriebskosten der erworbenen
                    Immobilie begleichen. Zugleich bleibst du von potenziellen Mieterhöhungen verschont – noch ein
                    Aspekt, um den du dich nach dem Kauf nicht länger sorgen musst.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt Kreditvergleich anfordern!
                    </a>
                </p>
                <h3>Kein Ärger mit Vermieter*innen</h3>
                <p>
                    Wieder nach 22 Uhr staubgesaugt? Zu lang und zu laut gefeiert? Oder der schlimmer noch: hast du
                    wieder vergessen, die Fenster auf dem Gang zu schließen? Mal ehrlich: Es gibt angenehmere
                    Beziehungen als die zur eigenen Vermieter*in. Zwar musst du auch als Wohnungseigentümer*in Rücksicht
                    auf deine Nachbar*innen nehmen – sollte es zu Problemen kommen, kannst du allerdings ein Gespräch
                    unter Gleichberechtigten führen. Das ist bei Diskussionen mit deiner Vermieter*in nicht der Fall.
                </p>
                <h3>Eigentum ist unbefristet</h3>
                <p>
                    Eine der beliebtesten Strategien, die Vermieter*innen einsetzen, um die eigenen Rechte auszudehnen,
                    ist die Befristung des Mietvertrages. Kein Wunder: Sind Mieter*innen doch eher darauf bedacht, sich
                    nichts zu Schulden kommen zu lassen, wenn die Verlängerung ihres Vertrags auf dem Spiel steht.
                    Entscheidest du dich für den Kauf, so hat auch die ständige Sorge um die Verlängerung des
                    Mietverhältnisses ein Ende.
                </p>
                <h3>Vorteile bei zukünftigen Krediten</h3>
                <p>
                    Auch für den Fall, dass du erneut einen Kredit aufnehmen musst, bietet der Besitz einer Immobilie
                    einen erheblichen Vorteil: Weil du dein Haus bzw. deine Wohnung als Sicherheit verwenden kannst,
                    werden Banken eher bereit sein, dir einen{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredit
                    </Link>{" "}
                    zu gewähren. Nutze unseren{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>
                    , um eine Gefühl für die potenziellen Finanzierungskosten mit deinem Budget zu bekommen.
                </p>
                <h3>
                    Deine Immobilie ist <strong>deine</strong> Immobilie
                </h3>
                <p>
                    Bei der Gestaltung einer Eigentumswohnung ist es nur das Baugesetz, das deiner Fantasie Grenzen
                    setzt. Auch hierbei ist es nicht nötig, eine Vermieter*in zu informieren, die – Überraschung – mit
                    den meisten deiner Pläne nicht einverstanden sein wird. Du willst jede Wand in einer anderen Farbe
                    streichen und mit Bildern jeder nur erdenklichen Vogelart tapezieren? Wir haben nichts dagegen.
                </p>
                <hr />

                <h2>Contra Eigentum</h2>
                <h3>Hoher Aufwand an Eigenmitteln</h3>
                <p>
                    Um eine Wohnung oder ein Haus zu kaufen, brauchst du eine bestimmte Summe an Eigenkapital. Zumindest
                    20, im Idealfall 30 % des Kaufpreises solltest du mit Eigenmitteln bestreiten können, damit Banken
                    dir einen Kredit gewähren. Im Falle eines Mietobjekts wird zu Beginn des Vertragsverhältnisses
                    lediglich die Kaution fällig, die darüber hinaus deutlich geringer ist.
                </p>
                <h3>Du benötigst Sicherheiten</h3>
                <p>
                    Bevor Kreditinstitute dich bei der Finanzierung einer Immobilie unterstützen, werden sie überprüfen,
                    ob du über genügend Sicherheiten verfügst, auf die sie zurückgreifen können, falls du
                    unerwarteterweise nicht in der Lage bist, deine Raten zu bezahlen. Auch bei einem Mietverhältnis
                    sprechen Sicherheiten sicherlich für dich; derart hoch wie bei einem Kauf sind sie allerdings nicht.
                </p>
                <h3>Jeder Kredit birgt Risiken</h3>
                <p>
                    Bei der Aufnahme eines{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredits
                    </Link>{" "}
                    musst du einige Risiken beachten, die im Falle der Wohnungsmiete schlichtweg nicht gegeben sind.
                    Entscheidest du dich für eine Finanzierung mit variabler Verzinsung, dann musst du etwa Reserven
                    einplanen, auf die du zurückgreifen kannst, falls die{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinsen
                    </Link>{" "}
                    auf dem Finanzmarkt steigen. Umgekehrt birgt die Wahl eines Fixzinssatzes das Risiko, bei sinkenden
                    Zinsen Geld zu verlieren.
                </p>
                <h3>Neue Pflichten als Eigentümer*in</h3>
                <p>
                    Besonders als Hauseigentümer*in kommen Verpflichtungen auf dich zu, die dir aus dem
                    Mieter*innendasein gänzlich unbekannt sind. Für die Abgrenzung vom rechts von deinem Haus gelegenen
                    Grundstück musst du ebenso sorgen wie für die Räumung des Gehsteigs im Winter und das Kürzen von
                    Ästen, die auf die Straße überhängen. Kommst du diesen Pflichten nicht nach, so haftest du für
                    Schäden und Verletzungen, die sich auf dein Versäumnis zurückführen lassen.
                </p>
                <hr />

                <h2>Fazit</h2>
                <p>
                    Sofern du über das nötige Startkapital und ein konstantes Einkommen verfügst, das es dir erlaubt,
                    die monatliche Rate problemlos zu begleichen, ist der Kauf einer Immobilie in den meisten Fällen die
                    bessere Option: Anders als bei der Miete investierst du die Summe der laufenden Kosten hier in eine
                    stabilen Wertanlage und damit direkt in deine Zukunft.
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"eigentum"}></BreadcrumbList>
            <ArticleStructuredData page={"eigentum"} heading={"Eigentum – Pro und Contra"} />
        </Layout>
    );
};

export default Eigentum;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.eigentum", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
